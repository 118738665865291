import React, {useEffect, useState} from "react";
import '../Form.css';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from "@mui/material/Button";
import Stack from '@mui/material/Stack';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import DeleteIcon from '@mui/icons-material/Delete';


import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import ColorPicker from '../admin/ColorPicker';

 
const PopupEditTaskCalendar = props => {
  const [titulo, setTitulo] = useState(props.content.titulo);
  const [abreviatura, setAbreviatura] = useState(props.content.abreviatura);
  const [rangodefechas, setRangodefechas] = useState(props.content.rangodefechas);
  const [colordefondo, setColordefondo] = useState(props.content.colordefondo);
  const [colordetexto, setColordeTexto] = useState(props.content.colordetexto);
  const [diadeducible, setDiadeducible] = useState(props.content.diadeducible);
  const [guardiacombinada, setGuardiacombinada] = useState(props.content.guardiacombinada);
  const [idservicio, setIdServicio] = useState(props.content.idservicio);
  const [tipoServicio, setTipoServicio] = useState(0);
  // const [horadeinicio, setHoradeinicio] = useState(new Date(new Date(props.content.fechainicio).getTime() - new Date(props.content.fechainicio).getTimezoneOffset() * 60 * 1000));
  // const [horadefin, setHoradefin] = useState(new Date(new Date(props.content.fechafin).getTime() - new Date(props.content.fechafin).getTimezoneOffset() * 60 * 1000));

  // const myArray = props.content.horariojornada.split(",");
  const [horadeinicio, setHoradeinicio] = useState(
    (props.content.fechainicio) ?
      props.content.fechainicio.replace(/Z/, ' ').replace(/\..+/, '')
      :
      props.content.fechainicio
  );
  const [horadefin, setHoradefin] = useState(
    (props.content.fechafin) ?
    props.content.fechafin.replace(/Z/, ' ').replace(/\..+/, '')
    :
    props.content.fechafin
  );


  let listadoHoras = [];
  if(props.content.horariojornada){
    listadoHoras = props.content.horariojornada.split(",");
  }
  const [horajornada, setHoraJornada] = useState(listadoHoras);  

  const handleChangeTitulo = (event) => {
    setTitulo(event.target.value);
  }
  const handleChangeAbreviatura = (event) => {
    setAbreviatura(event.target.value);
  }
  const handleChangeColorDeFondo = (data) => {
    setColordefondo(data);
  }
  const handleChangeColorDeTexto = (data) => {
    setColordeTexto(data);
  }
  const handleChangeHoraDeInicio = (event) => {
    setHoradeinicio(event.target.value);
  }
  const handleChangeHoraDeFin = (event) => {
    setHoradefin(event.target.value);
  }

  const [state, setState] = React.useState({
    rangofechas: (rangodefechas === '1') ? true : false,
    diadeducible: (diadeducible === '1') ? true : false,
    guardiacombinada: (guardiacombinada === '1') ? true : false,
  });

  const handleToggle = ({ target }) => {
    setTipoServicio(parseInt(target.value));
    // setState(s => ({ ...s, [target.name]: !s[target.name] }));
  }
  
  const handleToggle2 = ({ target }) => {
    // setTipoServicio(parseInt(target.value));
    setState(s => ({ ...s, [target.name]: !s[target.name] }));
  }

  

  const [valueStart, setValueStart] = React.useState(new Date());
  const handleChangeStart = (newValue) => {
    setValueStart(newValue.target.value);
    setHoradeinicio(newValue.target.value);
  };

  const [valueEnd, setValueEnd] = React.useState(new Date());
  const handleChangeEnd = (newValue) => {
    setValueEnd(newValue.target.value);
    setHoradefin(newValue.target.value);
  };

  const handleAddTurn = () => {

    // let fechaI = valueStart.toISOString();
		let fechaInicio = valueStart.replace(/T/, ' ').replace(/\..+/, '');

    // let fechaF = valueEnd.toISOString();
		let fechaFin = valueEnd.replace(/T/, ' ').replace(/\..+/, '');

    let nuewObj = `${fechaInicio}@${fechaFin}@${tipoServicio}`;
    if(0 === horajornada.length){
      setHoraJornada([nuewObj]);
    }else{
      setHoraJornada( [...horajornada, nuewObj] );
    }
  }

  const handleDeleteDateRange = (i) => {
    horajornada.splice(i, 1);
    setHoraJornada(horajornada);
  }


  useEffect(() => {
  }, [horajornada])
  

  return (
    <div className="popup-box">
      <div className="box">
        <span className="close-icon" onClick={() => props.handleClose(0)}>x</span>
        

        
        <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <TextField
            id="titulo"
            name="titulo"
            label="Título"
            fullWidth
            autoComplete="given-name"
            variant="standard"
            color="success"
            defaultValue={ titulo }
            onChange={handleChangeTitulo}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            id="abreviatura"
            name="abreviatura"
            label="Abreviatura"
            fullWidth
            autoComplete="family-name"
            variant="standard"
            color="success"
            defaultValue={ abreviatura }
            onChange={handleChangeAbreviatura}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControlLabel
            control={<Checkbox color="success" name="rangofechas" value="1" defaultChecked={state.rangofechas}/>}
            label="Rango de fechas"
            onChange={handleToggle2}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ColorPicker texto={'Color del fondo'} colordefondo={colordefondo} handlecolordefondo={handleChangeColorDeFondo}/>
        </Grid>
        <Grid item xs={12} sm={12}>
          <ColorPicker texto={'Color del texto'} colordefondo={colordetexto} handlecolordefondo={handleChangeColorDeTexto}/>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControlLabel
            control={<Checkbox color="success" name="diadeducible" value="1" defaultChecked={state.diadeducible}/>}
            label="Día deducible"
            onChange={handleToggle2}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControlLabel
            control={<Checkbox color="success" name="guardiacombinada" value="1" defaultChecked={state.guardiacombinada} />}
            label="Guardia combinada"
            onChange={handleToggle2}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={3}>

              <TextField
                id="datetime-local"
                label="Hora de inicio"
                type="datetime-local"
                defaultValue={horadeinicio}
                sx={{ width: 250 }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleChangeStart}
              />

              <TextField
                id="datetime-local"
                label="Hora de fin"
                type="datetime-local"
                defaultValue={horadeinicio}
                sx={{ width: 250 }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleChangeEnd}
              />

{
  (parseInt(idservicio) === 7) &&
        <>
          <FormControlLabel
            control={<Checkbox color="success" name="tipoalerta" value="1"/>}
            label="Tipo: Servicio"
            onChange={handleToggle}
          />
          <FormControlLabel
            control={<Checkbox color="success" name="diadeducible" value="2"/>}
            label="Tipo: Alerta"
            onChange={handleToggle}
          />
        </>
}


              <Button variant="outlined" color="error"
                onClick={() => {
                  handleAddTurn()
                }}
              >
              Añadir turno
              </Button>
            </Stack>
            
            <List>
              
              {
                horajornada.map(function(item, i){
                  const myArray = item.split("@");
                  let tipo = '';
                  if(myArray[2] === '1'){
                    tipo = 'Tipo: Servicio';
                  }else if(myArray[2] === '2'){
                    tipo = 'Tipo: Alerta';
                  }
                  return <ListItem disablePadding key={i}><ListItemButton onClick={ () => handleDeleteDateRange(i)}><ListItemIcon><DeleteIcon /></ListItemIcon><ListItemText>Inicio: {myArray[0]} <br /> Fin: {myArray[1]} <br /> {tipo}</ListItemText></ListItemButton></ListItem>
                })
              }
              

            </List>

          </LocalizationProvider>
        </Grid>
        
        <Grid item xs={12} sm={12} style={{ display: "flex", flexDirection: "row", justifyContent: 'space-around' }}>
          <Button variant="contained" color="success"
            onClick={() => {
              let data = {  
                idservicio: props.content.idservicio,
                titulo: titulo,
                abreviatura: abreviatura,
                rangodefechas: state.rangofechas,
                colordefondo: colordefondo,
                colordetexto: colordetexto,
                diadeducible: state.diadeducible,
                guardiacombinada: state.guardiacombinada,
                icon: 'format-list-bulleted-type',
                fechainicio: horadeinicio,
                fechafin: horadefin,
                ID: props.content.ID,
                idusuario: props.content.idusuario,
                horajornada: horajornada.toString()
                // fechainicio: new Date(horadeinicio.getTime() - horadeinicio.getTimezoneOffset() * 60 * 1000),
                // fechafin: new Date(horadefin.getTime() - horadefin.getTimezoneOffset() * 60 * 1000)
              }
              props.handlesavestask(data);
            }}
          >
            Guardar
          </Button>
          <Button variant="outlined" color="error"
            onClick={() => {
              props.handleBorrar(props.content.ID, props.content.idservicio, props.content.idusuario);
            }}
          >
           Borrar
          </Button>
           
        </Grid>
      </Grid>
    </React.Fragment>



      </div>
    </div>
  );
};


 
export default PopupEditTaskCalendar;