import React, { useState } from "react";
import Button from "@mui/material/Button";
import { SketchPicker, ChromePicker, CompactPicker, GithubPicker, TwitterPicker } from "react-color";

const ColorPicker = (props) => {
  const [color, setColor] = useState(props.colordefondo);
  const handleChange = (color) => { 
    props.handlecolordefondo(color.hex);
    setColor(color.hex);
  }
  const [displayColorPicker, setDisplayColorPicker] = useState(false);


  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker)
  };

  const handleClose = () => {
    props.handlecolordefondo(color);
    setDisplayColorPicker(false)
  };


  const popover = {
    position: 'inherit',
    zIndex: '2',
  }
  const cover = {
    position: 'inherit',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  }

  return (
    <div style={{ textalign: 'center' }}>
    <Button style={{backgroundColor: color}} variant="contained" onClick={ handleClick }>{props.texto}</Button>
    { displayColorPicker ? <div style={ popover }>
      <div style={ cover } onClick={ handleClose }/>
      <CompactPicker onChange={ handleChange } />
    </div> : null }
  </div>
  );
};

export default ColorPicker;