import React, {useState, useEffect} from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from "@mui/material/Button";
import {getTurns, deleteTurn} from '../../admin/Api';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from '@mui/icons-material/Delete';

import { useLocalStorage } from "../../admin/LocalStorage";

const Turns = (props) => {

	const [idUsuario, setIdUsuario] = useLocalStorage("idusuario", "");
	const [listadoTurnos, setListadoTurnos] = useState();

	useEffect(() => {
	  getTurns(idUsuario)
	  .then( (e) => {
		setListadoTurnos(e);
	  })
	}, [])
	

	const handleDeleteTurns = (id, idusuario) => {
		deleteTurn(id, idusuario)
		.then( (e) => {
			props.cerrar();
		})
	}


return (
	<>
	<h2>TURNOS</h2>
	 <List>
              
		{
			listadoTurnos ?
			listadoTurnos.map(function(item, i){
				// const myArray = item.split("@");
				return <ListItem disablePadding key={i}><ListItemButton onClick={ () => handleDeleteTurns(item.ID, item.idusuario)}><ListItemIcon><DeleteIcon /></ListItemIcon><ListItemText>Inicio: {item.fechainicio} <br /> Fin: {item.fechafin}</ListItemText></ListItemButton></ListItem>
			})
			:
			<></>
		}
		

	</List>
	<div>
		<Button variant="outlined" color="error"
			onClick={() => {
				props.cerrar();
			}}
			>
			Cerrar
		</Button>
	  </div>
    </>
);
};

export default Turns;
