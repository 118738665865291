import React from 'react';
import {
Nav,
NavLink,
Bars,
NavMenu,
NavBtn,
NavBtnLink,
} from './NavbarElements';

const Navbar = () => {
return (
	<>
	<Nav 
	className='nav'
	style={{
		// position: 'fixed',
		// bottom: 0,
		marginTop: 20,
		marginBottom: 20,
		width: '100%',
		border: 'solid 1px #005125',
		background: '#ffffff',
		padding: 0
	}}>
		{/* <Bars /> */}

		<NavMenu className='navmenu' >
		<NavLink to='/cuadrante' activeStyle>
			<img src="calendar.svg" className="Nav__logo" />
		</NavLink>
		<NavLink to='/settings' activeStyle>
		<img src="usersettings.svg" className="Nav__logo" />
		</NavLink>
		{/* Second Nav */}
		{/* <NavBtnLink to='/sign-in'>Sign In</NavBtnLink> */}
		</NavMenu>
	</Nav>
	</>
);
};

export default Navbar;
