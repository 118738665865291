import React, {useState, useEffect} from 'react';

import Button from "@mui/material/Button";

import {InitialTask} from '../../admin/InitialTask';
import { useLocalStorage } from "../../admin/LocalStorage";
import {getServiciosByUserId, saveInitialConfig, actualizarServicio, deleteSevices} from '../../admin/Api';

import PopupServiceType from '../PopupServiceType';
import { id } from 'date-fns/locale';

const ServiceType = () => {
	const [idUsuario, setIdUsuario] = useLocalStorage("idusuario", "");
	const [servicios, setServicios] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const [typeOfSetting, setTypeOfSetting] = useState();

	useEffect(() => {
		getServiciosByUserId(idUsuario)
		.then( (e) => {
		  setServicios(e);
		  if(e.length === 0){
        	setServicios(InitialTask);
        	InitialTask.forEach(element => {
			  saveInitialConfig(element, idUsuario);
			});
		  }
		})

	  return () => {
	  }
	}, [])
	
	const handleServiceType = (data) => {
    	setTypeOfSetting(data);
		setIsOpen(!isOpen);
  }


	const togglePopup = (data) => {
		setIsOpen(!isOpen);
	}

	const handleSavesTask = (data) => {
		actualizarServicio(data)
		.then( (e) => {
			getServiciosByUserId(idUsuario)
			.then( (e) => {
				setServicios(e);
			})
			setIsOpen(!isOpen);
		})
	}

	const handleBorrar = (data) => {
		console.log(data);
		deleteSevices(idUsuario, data)
		.then( (e) => {
			console.log(e);
			getServiciosByUserId(idUsuario)
			.then( (e) => {
			setServicios(e);
			})
			setIsOpen(!isOpen);
		})
	}

return (
  <>
	<div
	style={{
		display: 'flex',
		justifyContent: 'flex-start',
		flexDirection: 'column',
		alignItems: 'normal',
		height: '100vh',
	}}
	>
	<div>
		<ul>
			{servicios.map((prop, key) => {
			return (
				// <Button style={{borderColor: prop[0]}}  key={key}>{prop[1]}</Button>
				<li key={prop.ID}>
				<img src="settingslist.svg" alt="settings" className="li__logo" />
				<span onClick={() => handleServiceType(prop)} style={{fontSize: 12}}>{prop.titulo}</span>
				</li>
			);
			})}
		</ul>
	</div>
	</div>
  {isOpen && <PopupServiceType
	content={typeOfSetting}
	handleClose={togglePopup}
	handlesavestask={handleSavesTask}
	handleBorrar={handleBorrar}
	/>}
  </>
);
};

export default ServiceType;
