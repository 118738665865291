import React from "react";

import ServiceType from './typesPopup/Servicetype';
import AddServiceType from './typesPopup/Addservicetype';
import ServiceAddCuadrante from './typesPopup/ServiceAddCuadrante';
import Turns from './typesPopup/Turns';
import AddTurns from './typesPopup/AddTurns';
import Commisions from './typesPopup/Commisions';
import Search from './typesPopup/Serach';
import Goto from './typesPopup/Goto';
import Settings from './typesPopup/Settings';
import DeleteDateRange from './typesPopup/DeleteDateRange';
 
const Popup = props => {
  
 
  return (
    <div className="popup-box">
      <div className="box">
        <span className="close-icon" onClick={props.handleClose}>x</span>
        {( () => {
          switch(props.content){
            case 'servicetype':
              return (
              <ServiceType />
              );
              break;
            case 'addservicetype':
              return (
              <AddServiceType cerrar={props.handleClose}/>
              );
              break;
            case 'turns':
              return (
              <Turns cerrar={props.handleClose}/>
              );
              break;
            case 'addturns':
              return (
              <AddTurns cerrar={props.handleClose} />
              );
              break;
            case 'commisions':
              return (
              <Commisions />
              );
              break;
            case 'search':
              return (
              <Search />
              );
              break;
            case 'goto':
              return (
              <Goto />
              );
              break;
            case 'settings':
              return (
              <Settings cerrar={props.handleClose} />
              );
              break;
            case 'deletedaterange':
              return (
              <DeleteDateRange cerrar={props.handleClose}/>
              );
              break;
            case 'serviceaddcuadrante':
              return (
                <ServiceAddCuadrante 
                diaclicado={props.diaclicado}
                dateClicked={props.dateClicked}
                cerrar={props.handleClose}
                />
              )
          }
        })()}
      </div>
    </div>
  );
};


 
export default Popup;