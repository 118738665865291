import React, { useState, useEffect } from 'react';
import '../App.css';
import {getServiciosByUserId, getHourForMonth, actualizarServicio, deleteCalendarTask, createTask, getTasks, getTask, getCalendarTask, updateCalendarTask} from '../admin/Api';
import { useLocalStorage } from "../admin/LocalStorage";

import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction"
import timeGridPlugin from '@fullcalendar/timegrid';
import Popup from '../components/Popup';
import PopupEditTaskCalendar from '../components/PopupEditTaskCalendar';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from "@mui/material/Button";
import Stack from '@mui/material/Stack';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';



const Cuadrante = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [isOpenAddNew, setIsOpenAddNew] = useState(false);
	const [isOpenEvent, setIsOpenEvent] = useState(false);
	const [isOpenEditTask, setIsOpenEditTask] = useState(false);
	const [itemsByMonth, setItemsByMonth] = useState({});
	const [eventOpen, setEventOpen] = useState({});
	const [idUsuario, setIdUsuario] = useLocalStorage("idusuario", "");
	const [servicios, setServicios] = useState([]);
	const [dayClicked, setDayClicked] = useState();
	const [dateClicked, setDateClicked] = useState();
	const [horasTrabajadasMes, setHorasTrabajadasMes] = useState(1);
	const [horasReferencia, setHorasReferencia] = useState(1);
	const [horasExcesoMes, setHorasExcesoMes] = useState(1);
	const [horasConsumidas, setHorasConsumidas] = useState(0);
	const [openCalendarEvent, setOpenCalendarEvent] = useState();
	const [reduccionJornada, setReduccionJornada] = useState(0);

	const [horasDSJ, setHorasDSJ] = useState(0);

	const [actualMonth, setActualMonth] = useState();
	const [actualYear, setActualYear] = useState();


	  const handleDateClick = (arg) => { 
		  let dateClick = arg.date;
		  let dayOfClicked = arg.dateStr;
		setDayClicked(dayOfClicked);
		setDateClicked(dateClick);
		getTask(idUsuario, dayOfClicked)
		.then( (e) => {
			if(e.length === 0){
				console.log('BBBBBBBBBB')
				getServiciosByUserId(idUsuario)
				.then( (e) => {
					setServicios(e);
					setEventOpen('serviceaddcuadrante');
					setIsOpenEvent(!isOpenEvent);
				})
			}else{
				console.log('SET SERVICES FOR EDIT in cuadrante.js')
				getServiciosByUserId(idUsuario)
				.then( (e) => {
					setServicios(e);
					setEventOpen('serviceaddcuadrante');
					setIsOpenEvent(!isOpenEvent);
				})
			}
		})
	  }

	  //Editar evento creado
	  const renderEventContent = (eventInfo) => {
		// setEventOpen(eventInfo.event._def.extendedProps);
		getCalendarTask(eventInfo.event._def.extendedProps.idusuario, eventInfo.event._def.extendedProps.idevent)
		.then( (e) => {
			setOpenCalendarEvent(e[0]);
			setIsOpenEditTask(!isOpenEditTask);
		})
	  }
	
	  const addNewEvent = () => {
		setIsOpenAddNew(!isOpen);
	  }
	
	  const togglePopup = () => {
		setIsOpen(!isOpen);
	  }
	
	  const togglePopupAddNew = () => {
		setIsOpenAddNew(!isOpenAddNew);
	  }

	  const togglePopupEvent = () => {
		setIsOpenEvent(!isOpenEvent);
		refreshCuurentMonth(actualMonth, actualYear);
	  }

	  const togglePopupEventEditTask = () => {
		setIsOpenEditTask(!setIsOpenEditTask);
		refreshCuurentMonth(actualMonth, actualYear);
	  }

	  function objectLength(obj) {
		var result = 0;
		for(var prop in obj) {
			if (obj.hasOwnProperty(prop)) {
			result++;
			}
		}
		return result;
	}

	  const handleCuurentMonth = (data) => {
		  
		  let month = data.view.getCurrentData().currentDate.getMonth();
		  let year = data.view.getCurrentData().currentDate.getFullYear();
		  console.log('Cambio de mes', month)
		  
		let usertasks = {
			"idusuario": idUsuario,
			"year": year,
			"month": month+1,
			"day": '01',
		}

		getTasks(usertasks)
		.then( (e) => {
			setHorasTrabajadasMes(e.totalHoursMonth);// horas referencias reales
			setHorasReferencia(e.horasReferencia);
			setHorasConsumidas(e.horasConsumidas);
			if(e.restOfHoursMonth < 0){
				setHorasExcesoMes(Math.abs(e.restOfHoursMonth));
			}else{
				setHorasExcesoMes(-e.restOfHoursMonth);
			}
			
			// setHorasTrabajadasMes(e.totalHoursMonth);
			// setHorasReferencia(e.horasReferencia);
			// if(e.restOfHoursMonth < 0){
			// 	setHorasExcesoMes(e.restOfHoursMonth);
			// }else{
			// 	setHorasExcesoMes(-e.restOfHoursMonth);
			// }
			setItemsByMonth(e.daysOfMonths);
			setActualMonth(month);
		  	setActualYear(year);
		})
		.catch( (err) => {
			console.log('Controlar erro usertask')
		});

	  }

	  const refreshCuurentMonth = (month, year) => {
	  
	  let usertasks = {
		  "idusuario": idUsuario,
		  "year": year,
		  "month": month+1,
		  "day": '01',
	  }

	  getTasks(usertasks)
	  .then( (e) => {

		setHorasTrabajadasMes(e.totalHoursMonth); //horas referencias realies
		setHorasReferencia(e.horasReferencia);
		setHorasConsumidas(e.horasConsumidas);
		if(e.restOfHoursMonth < 0){
			setHorasExcesoMes(Math.abs(e.restOfHoursMonth));
		}else{
			setHorasExcesoMes(-e.restOfHoursMonth);
		}
		setItemsByMonth(e.daysOfMonths);

		//   setHorasTrabajadasMes(e.totalHoursMonth);
		//   if(e.restOfHoursMonth < 0){
		// 	  setHorasExcesoMes(e.restOfHoursMonth);
		//   }else{
		// 	  setHorasExcesoMes(-e.restOfHoursMonth);
		//   }
		//   setItemsByMonth(e.daysOfMonths);
	  });

	}

	const handleDeleteTask = (idevent, idservicio, idusuario) => {
		deleteCalendarTask(idevent,idservicio,idusuario)
		.then( (e) => {
			togglePopupEventEditTask();
		})
	}
	const handleSavesTask = (data) => {
		console.log('actualizar task', data);
		updateCalendarTask(data)
		.then( (e) => {
			togglePopupEventEditTask();
		})
		
	}

	  return (
		<>
		<div className="App">
			<FullCalendar
			firstDay={1}
			  plugins={[ dayGridPlugin, interactionPlugin ]}
			  headerToolbar = {{
				start: 'new',
				center: 'title',   
				end: 'prev,next'
			  }}
			  customButtons={{
				new: {
				  text: '+',
				  click: () => addNewEvent(),
				},
			  }}
			  initialView="dayGridMonth"
			  locale={'es'}
			  dateClick={handleDateClick}
			//   weekends={false}
			//   events={[
			// 	{ 
			// 	  title: 'WS', 
			// 	  start: '2022-09-01', 
			// 	  end: '2022-09-05', 
			// 	  backgroundColor: '#378006',
			// 	  borderColor: 'red',
			// 	  textColor: '#000000', 
			// 	  editable: true, 
			// 	  idevent: '123456789' ,
			// 	  idusuario: '14303',
			// 	  idservicio: '5',
			// 	},
			//   ]}
			  events={itemsByMonth}
			  eventClick={renderEventContent}
			  datesSet={(dd) => handleCuurentMonth(dd)}
			/>
		  
			
		</div>
		<div style={{marginTop: 20}}>
		<Box sx={{ flexGrow: 1 }}>
			<Grid container spacing={2}>
				<Grid item xs={6} sm={3} md={3}>
				<TextField
					disabled
					id="outlined-disabled"
					label="Horas de referencia genéricas"
					value={
						horasReferencia 
						?
						horasReferencia
						:
						0
					}
					/>
				</Grid>
				<Grid item xs={6} sm={3} md={3}>
				<TextField
					disabled
					id="outlined-disabled"
					label="Horas de referencia reales"
					value={
						horasTrabajadasMes
						?
						horasTrabajadasMes
						:
						0
					}
					/>
				</Grid>
				<Grid item xs={6} sm={3} md={3}>
				<TextField
					disabled
					id="outlined-disabled"
					label="Horas computables"
					value={
						horasConsumidas
						?
						horasConsumidas
						:
						0
					}
					/>
				</Grid>
				<Grid item xs={6} sm={3} md={3}>
				<TextField
					disabled
					id="outlined-disabled"
					label="Horas de exceso"
					value={
						horasExcesoMes
						?
						horasExcesoMes
						:
						0
					}
					/>
				</Grid>
			</Grid>
			</Box>
		</div>

		{/* {isOpen && <Popup
			content={<>
			  <b>List Events</b>
			  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
			  <button>Test button</button>
			</>}
			handleClose={togglePopup}
		  />} */}
	
		{isOpenEditTask && <PopupEditTaskCalendar
			content={openCalendarEvent}
			handleClose={togglePopupEventEditTask}
			handlesavestask={handleSavesTask}
			handleBorrar={handleDeleteTask}
		  />}

			{isOpenEvent && <Popup
			content={eventOpen}
			handleClose={togglePopupEvent}
			listservices={servicios}
			diaclicado={dayClicked}
			dateClicked={dateClicked}
			// content={typeOfSetting}
			// handlesavestask={handleSavesTask}
			// handleBorrar={handleBorrar}
		  />}
		</>
	  );
};


export default Cuadrante;
