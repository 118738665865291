import React, {useState} from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from "@mui/material/Button";
import { useLocalStorage } from "../../admin/LocalStorage";
import {deleteTurnsByDateRange} from '../../admin/Api';

const DeleteDateRange = (props) => {

	const [fechaInicio, setFechaInicio] = useState();
	const [fechaFin, setFechaFin] = useState();
	const [idUsuario, setIdUsuario] = useLocalStorage("idusuario", "");
	
	const handleStartChange = (event) => {
		setFechaInicio(event.target.value);
	};

	const handleEndChange = (event) => {
		setFechaFin(event.target.value);
	};

	const handleDeleteTurns = () => {
		console.log('Manejamos elminar por fecha');
		let data = {
			idusuario: idUsuario,
			fechainicio: fechaInicio,
			fechafin: fechaFin
		}
		deleteTurnsByDateRange(data)
		.then( (e) => {
			props.cerrar();
		})
	}

	

return (
	<>
	<div>
      <TextField
        id="date"
        label="Fecha Inicial"
        type="date"
        // defaultValue="2017-05-24"
        sx={{ width: 220 }}
        InputLabelProps={{
          shrink: true,
        }}
		onChange={handleStartChange}
      />
	  <br/><br/><br/>
	   <TextField
        id="date"
        label="Fecha Final"
        type="date"
        // defaultValue="2017-05-24"
        sx={{ width: 220 }}
        InputLabelProps={{
          shrink: true,
        }}
		onChange={handleEndChange}
      />
      {/* <TextField
        id="time"
        label="Alarm clock"
        type="time"
        defaultValue="07:30"
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 300, // 5 min
        }}
        sx={{ width: 150 }}
      />
      <TextField
        id="datetime-local"
        label="Next appointment"
        type="datetime-local"
        defaultValue="2017-05-24T10:30"
        sx={{ width: 250 }}
        InputLabelProps={{
          shrink: true,
        }}
      /> */}
	  </div>
	  <br/>
	  <div>
		<Button variant="outlined" color="success" style={{ marginRight: 7}}
			onClick={() => {
				handleDeleteTurns()
			}}
			>
			Eliminar
		</Button>
		<Button variant="outlined" color="error"
			onClick={() => {
				props.cerrar();
			}}
			>
			Cerrar
		</Button>
	  </div>
    </>
);
};

export default DeleteDateRange;
