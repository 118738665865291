export const InitialTask = [
    // {
    //   idservicio: 1,
    //   titulo: 'ALERTA',
    //   abreviatura: 'A',
    //   rangodefechas: true,
    //   colordefondo: '#5da2a0',
    //   colordetexto: '#FFD633',
    //   diadeducible: false,
    //   guardiacombinada: true,
    //   icon: 'format-list-bulleted-type',
    //   tiposervidio: 'DDDDDD'
    // },
    {
      idservicio: 2,
      titulo: 'ASUNTOS PARTICUALRES',
      abreviatura: 'AP',
      rangodefechas: false,
      colordefondo: '#7aa25d',
      colordetexto: '#FFD633',
      diadeducible: true,
      guardiacombinada: false,
      icon: 'format-list-bulleted-type',
      tiposervidio: 'DDDDDD'
    },
    {
      idservicio: 3,
      titulo: 'BAJA',
      icon: 'format-list-bulleted-type',
      abreviatura: 'BAJ',
      rangodefechas: true,
      colordefondo: '#a25d81',
      colordetexto: '#FFD633',
      diadeducible: true,
      guardiacombinada: false,
      tiposervidio: 'DDDDDD'
    },
    // {
    //   idservicio: 4,
    //   titulo: 'DESCANSO COMPENSATORIO',
    //   abreviatura: 'DC',
    //   rangodefechas: false,
    //   colordefondo: '#2d12df',
    //   colordetexto: '#FFD633',
    //   diadeducible: false,
    //   guardiacombinada: false,
    //   icon: 'format-list-bulleted-type',
    //   tiposervidio: 'DDDDDD'
    // },
    {
      idservicio: 5,
      titulo: 'DESCANSO SEMANAL',
      abreviatura: 'DS',
      rangodefechas: true,
      colordefondo: '#d1df12',
      colordetexto: '#FFD633',
      diadeducible: false,
      guardiacombinada: false,
      icon: 'format-list-bulleted-type',
      tiposervidio: 'DDDDDD'
    },
    {
      idservicio: 6,
      titulo: 'DESCANSO SINGULARIZADO',
      abreviatura: 'DSI',
      rangodefechas: false,
      colordefondo: '#12df4e',
      colordetexto: '#FFD633',
      diadeducible: true,
      guardiacombinada: false,
      icon: 'format-list-bulleted-type',
      tiposervidio: 'DDDDDD'
    },
    {
      idservicio: 7,
      titulo: 'GUARDIA COMBINADA',
      abreviatura: 'GC',
      rangodefechas: false,
      colordefondo: '#1a3823',
      colordetexto: '#FFD633',
      diadeducible: false,
      guardiacombinada: true,
      icon: 'format-list-bulleted-type',
      tiposervidio: 'DDDDDD'
    },
    {
      idservicio: 8,
      titulo: 'INDISPOSICION',
      abreviatura: 'IND',
      rangodefechas: true,
      colordefondo: '#322272',
      colordetexto: '#FFD633',
      diadeducible: true,
      guardiacombinada: false,
      icon: 'format-list-bulleted-type',
      tiposervidio: 'DDDDDD'
    },
    {
      idservicio: 9,
      titulo: 'JUICIO',
      abreviatura: 'JUI',
      rangodefechas: false,
      colordefondo: '#0ebfc8',
      colordetexto: '#FFD633',
      diadeducible: false,
      guardiacombinada: false,
      icon: 'format-list-bulleted-type',
      tiposervidio: 'DDDDDD'
    },
    {
      idservicio: 11,
      titulo: 'LIBRE FESTIVO',
      abreviatura: 'DF',
      rangodefechas: false,
      colordefondo: '#322272',
      colordetexto: '#FFD633',
      diadeducible: true,
      guardiacombinada: false,
      icon: 'format-list-bulleted-type',
      tiposervidio: 'DDDDDD'
    },
    {
      idservicio: 12,
      titulo: 'MAÑANA',
      abreviatura: 'M',
      rangodefechas: false,
      colordefondo: '#c80e0e',
      colordetexto: '#FFD633',
      diadeducible: false,
      guardiacombinada: false,
      icon: 'format-list-bulleted-type',
      tiposervidio: 'DDDDDD'
    },
    {
      idservicio: 13,
      titulo: 'MAÑANA-NOCHE',
      abreviatura: 'MN',
      rangodefechas: false,
      colordefondo: '#322272',
      colordetexto: '#FFD633',
      diadeducible: false,
      guardiacombinada: false,
      icon: 'format-list-bulleted-type',
      tiposervidio: 'DDDDDD'
    },
    {
      idservicio: 14,
      titulo: 'NOCHE',
      abreviatura: 'N',
      rangodefechas: false,
      colordefondo: '#905c5c',
      colordetexto: '#FFD633',
      diadeducible: false,
      guardiacombinada: false,
      icon: 'format-list-bulleted-type',
      tiposervidio: 'DDDDDD'
    },
    {
      idservicio: 15,
      titulo: 'PERMISO',
      abreviatura: 'PER',
      rangodefechas: true,
      colordefondo: '#322272',
      colordetexto: '#FFD633',
      diadeducible: true,
      guardiacombinada: false,
      icon: 'format-list-bulleted-type',
      tiposervidio: 'DDDDDD'
    },
    {
      idservicio: 16,
      titulo: 'PUERTAS',
      abreviatura: 'P',
      rangodefechas: false,
      colordefondo: '#d87813',
      colordetexto: '#FFD633',
      diadeducible: false,
      guardiacombinada: false,
      icon: 'format-list-bulleted-type',
      tiposervidio: 'DDDDDD'
    },
    {
      idservicio: 17,
      titulo: 'TARDE',
      abreviatura: 'T',
      rangodefechas: false,
      colordefondo: '#b991d0',
      colordetexto: '#FFD633',
      diadeducible: false,
      guardiacombinada: false,
      icon: 'format-list-bulleted-type',
      tiposervidio: 'DDDDDD'
    },
    {
      idservicio: 18,
      titulo: 'VACACIONES',
      abreviatura: 'VAC',
      rangodefechas: true,
      colordefondo: '#322272',
      colordetexto: '#FFD633',
      diadeducible: true,
      guardiacombinada: false,
      icon: 'format-list-bulleted-type',
      tiposervidio: 'DDDDDD'
    },
    {
      idservicio: 19,
      titulo: 'PERMISO URGENTE',
      abreviatura: 'PU',
      rangodefechas: false,
      colordefondo: '#d92699',
      colordetexto: '#FFD633',
      diadeducible: true,
      guardiacombinada: false,
      icon: 'format-list-bulleted-type',
      tiposervidio: 'DDDDDD'
    },
    {
      idservicio: 20,
      titulo: 'DESCANSO NO DEDUCIBLE',
      abreviatura: 'DND',
      rangodefechas: false,
      colordefondo: '#26d9ac',
      colordetexto: '#FFD633',
      diadeducible: false,
      guardiacombinada: false,
      icon: 'format-list-bulleted-type',
      tiposervidio: 'DDDDDD'
    },
    {
      idservicio: 21,
      titulo: 'SALIENTE',
      abreviatura: 'S',
      rangodefechas: false,
      colordefondo: '#cfd926',
      colordetexto: '#FFD633',
      diadeducible: false,
      guardiacombinada: false,
      icon: 'format-list-bulleted-type',
      tiposervidio: 'DDDDDD'
    },
    {
      idservicio: 22,
      titulo: 'SALIENTE NOCHE',
      abreviatura: 'SN',
      rangodefechas: false,
      colordefondo: '#0c4338',
      colordetexto: '#FFD633',
      diadeducible: false,
      guardiacombinada: false,
      icon: 'format-list-bulleted-type',
      tiposervidio: 'DDDDDD'
    }
  ]