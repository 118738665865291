import React, {useState, useEffect} from "react";
import '../../Form.css';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from "@mui/material/Button";
import Stack from '@mui/material/Stack';

import PopupSettings from "../PopupSettings";
import { useLocalStorage } from "../../admin/LocalStorage";
import {saveOptions, getUserConfig, getHolidays, getPrivateAffairs} from '../../admin/Api';

 
const Settings = props => {
	const [idUsuario, setIdUsuario] = useLocalStorage("idusuario", "");
	const [isOpen, setIsOpen] = useState(false);
	const [settingType, setSettingType] = useState();
	const [holidays, setHolidays] = useState();
	const [privateAffairs, setPrivateAffairs] = useState();
	const [formData, setformData] = useState(defaultFormValues());

	const objectLength = (obj) => {
		var result = 0;
		for(var prop in obj) {
			if (obj.hasOwnProperty(prop)) {
			result++;
			}
		}
		return result;
	}

	useEffect(() => {
		getUserConfig(idUsuario)
		.then( (e) => {
			if(e[0]){
				console.log('HHHHH', JSON.parse(e[0].options))
				setformData(JSON.parse(e[0].options));
			}
			getHolidays(idUsuario)
			.then( (ee) => {
				setHolidays(objectLength(ee));
				getPrivateAffairs(idUsuario)
				.then( (eee) => {
					setPrivateAffairs(objectLength(eee));
				})
			})
		//   setServicios(e);
		//   if(e.length === 0){
        // 	setServicios(InitialTask);
        // 	InitialTask.forEach(element => {
		// 	  saveInitialConfig(element, idUsuario);
		// 	});
		//   }
		});

	  return () => {
	  }
	}, [])

    const [state, setState] = React.useState({
		// jornada1: (horassemana37 === '1') ? true : false,
		// jornada2: (horassemana40 === '1') ? true : false,
	});

	const handleToggle = ({ target }) => {
		setState(s => ({ ...s, [target.name]: !s[target.name] }));
	  }
  
  const togglePopup = (data) => {
	setIsOpen(!isOpen);
	}

  const handleClick = (event) => {
	setSettingType(event);
	setIsOpen(!isOpen);
  }

  const handleSaveSetting = (e, type) => {
	setformData( { ...formData, [type]: e});
}
const handleSave = () => {
	console.log('Salvar el formulario', formData)
	saveOptions(formData, idUsuario)
	.then((e) => {
		props.cerrar();
	})
}
 


  return (
    <div className="popup-box">
      <div className="box" style={{ textAlign: 'left'}}>
        <span className="close-icon" onClick={() => props.cerrar()}>x</span>
        <>
			<h2>JORNADA LABORAL</h2>
			<div onClick={ () => handleClick('horassemanales') }>
				<h4 style={{ marginBottom: -10 }}>Horas Semanales</h4>
				<p>Elija la jornada de cómputo adecuada a su especialidad.</p>
			</div>
			<div onClick={ () => handleClick('computohoras') }>
				<h4 style={{ marginBottom: -10 }}>Cómputo de horas</h4>
				<p>Elija el tipo de cómputo de horas.</p>
			</div>
			<div onClick={ () => handleClick('reduccionjornada') }>
				<h4 style={{ marginBottom: -10 }}>Reducción de jornada</h4>
				<p>Especifique el % de reducción de jornada a aplicar.</p>
			</div>
			<hr
				style={{
					color: 'black',
					backgroundColor: 'black',
					width: '100%',
					height: 2,
					marginLeft: 3,
				}}
			/>
			
			<h2>VACACIONES</h2>
			<div onClick={ () => handleClick('diadevacaciones') }>
				<h4 style={{ marginBottom: -10 }}>Días de vacaciones</h4>
				<p>Del 1 de Febrero al 31 de Enero.</p>
			</div>
			{/* <div onClick={ () => handleClick('tipodeservicio') }>
				<h4 style={{ marginBottom: -10 }}>Tipo de servicio</h4>
				<p>Elija el tipo de servicio que corresponde con las vacaciones.</p>
			</div> */}
			<div>
				<h4 style={{ marginBottom: -10 }}>Resumen</h4>
				<p>Gastados: {holidays} días.</p>
				<p>Restantes: {formData.diasdevacaciones-holidays} días.</p>
			</div>
			<hr
				style={{
					color: 'black',
					backgroundColor: 'black',
					width: '100%',
					height: 2,
					marginLeft: 3
				}}
			/>

			<h2>ASUNTOS PARTICULARES</h2>
			<div onClick={ () => handleClick('asuntosparticulares') }>
				<h4 style={{ marginBottom: -10 }}>Asuntos particulares</h4>
				<p>Del 1 de Febrero al 31 de Enero.</p>
			</div>
			{/* <div onClick={ () => handleClick('tiposervicioasuntosparticulares') }>
				<h4 style={{ marginBottom: -10 }}>Tipo de servicio</h4>
				<p>Elija el tipo de servicio que corresponde con los asuntos particulares.</p>
			</div> */}
			<div>
				<h4 style={{ marginBottom: -10 }}>Resumen</h4>
				<p>Gastados: {privateAffairs} días.</p>
				<p>Restantes: {formData.asuntosparticulares-privateAffairs} días.</p>
			</div>
			<hr
				style={{
					color: 'black',
					backgroundColor: 'black',
					width: '100%',
					height: 2,
					marginLeft: 3
				}}
			/>

			{/* <h2>COMISIONES</h2>
			<div onClick={ () => handleClick('dietadiarioa') }>
				<h4 style={{ marginBottom: -10 }}>Dieta diaria</h4>
				<p>Dieta habitual en euros. Debe incluir la manutención completa. Después dentro de cada comisión puede  modificar este valor individualmente.</p>
			</div>
			<div onClick={ () => handleClick('manutenciondiaria') }>
				<h4 style={{ marginBottom: -10 }}>Manutención diaria</h4>
				<p>Manutención completa habitual en euros. Después durante las comisiones según se tenga derecho o no, se realizarán cálculos dejángola a media.</p>
			</div>
			<div onClick={ () => handleClick('visualizardietatotal') }>
				<h4 style={{ marginBottom: -10 }}>Visualizar dieta total</h4>
				<p>Cómo quiere que se vea el total de la dieta para cada comisión.</p>
			</div>
			<div onClick={ () => handleClick('longitudendias') }>
				<h4 style={{ marginBottom: -10 }}>Longitud en días</h4>
				<p>A partir de cuantos días se considera que una comisión es larga o corta.</p>
			</div>
			<div onClick={ () => handleClick('mostrarmanutenciondeundia') }>
				<h4 style={{ marginBottom: -10 }}>Mostrar manutención de un día</h4>
				<p>Para comisiones de un solo día, si se quiere mostrar el derecho a recibir la manutención completa o media, según el horario del servicio.</p>
			</div>
			<hr
				style={{
					color: 'black',
					backgroundColor: 'black',
					width: '100%',
					height: 2,
					marginLeft: 3
				}}
			/> */}
			<div style={{ display: 'flex', justifyContent: 'space-around' }}>
			<Button 
				variant="contained" 
				color="success" 
				onClick={() => handleSave()}>
				Guardar
			</Button>
			<Button 
				variant="contained" 
				color="success" 
				onClick={() => props.cerrar()}>
				Cerrar
			</Button>
			</div>
			{isOpen && <PopupSettings
				content={settingType}
				handleClose={togglePopup}
				handlesavesetting={handleSaveSetting}
				formData={formData}
			/>}

    	</>
      </div>
    </div>
  );
};

function defaultFormValues(){
	return{
	  horassemanales: "",
	  computohoras: "",
	  reduccionjornada: "",
	  diasdevacaciones: "",
	  tiposerviciovacaciones: "",
	  asuntosparticulares: "",
	  tiposervicioasuntosparticualres: "",
	  dietadiaria: "",
	  manutenciondiaria: "",
	  visualizardietatotal: false,
	  verlargarcortas: false,
	  longituddias: "",
	  mostrarmanutenciondeundia: false
	}
  }

export default Settings;
