import React, {useState} from 'react';
import Popup from '../components/Popup';

const Settings = () => {

	const [isOpen, setIsOpen] = useState(false);
	const [isOpenAddNew, setIsOpenAddNew] = useState(false);

	const [typeOfSetting, setTypeOfSetting] = useState();

	const togglePopup = () => {
		setIsOpen(!isOpen);
	  }
	
	  const togglePopupAddNew = () => {
		setIsOpenAddNew(!isOpenAddNew);
	  }

	  const handleDateClick = (type) => { 
		setTypeOfSetting(type);
		setIsOpen(!isOpen);
	  }
	  

return (
	<>

  <ul>
    <li><img src="settingslist.svg" alt="settings" className="li__logo" /><a href="#" onClick={() => handleDateClick('servicetype')}>Tipos de servicio</a></li>
    <li><img src="settingslist.svg" alt="settings" className="li__logo" /><a href="#" onClick={() => handleDateClick('addservicetype')}>Añadir tipos de servicios</a></li>
    <li><img src="settingslist.svg" alt="settings" className="li__logo" /><a href="#" onClick={() => handleDateClick('addturns')}>Añadir turnos</a></li>
    {/* <li><img src="settingslist.svg" alt="settings" className="li__logo" /><a href="#" onClick={() => handleDateClick('turns')}>Turnos</a></li> */}
    {/* <li><img src="settingslist.svg" alt="settings" className="li__logo" /><a href="#" onClick={() => handleDateClick('commisions')}>Comisiones</a></li> */}
	{/* <li><img src="settingslist.svg" alt="settings" className="li__logo" /><a href="#" onClick={() => handleDateClick('search')}>Buscar</a></li> */}
	{/* <li><img src="settingslist.svg" alt="settings" className="li__logo" /><a href="#" onClick={() => handleDateClick('goto')}>Ir a</a></li> */}
	<li><img src="settingslist.svg" alt="settings" className="li__logo" /><a href="#" onClick={() => handleDateClick('settings')}>Configuración</a></li>
	<li><img src="settingslist.svg" alt="settings" className="li__logo" /><a href="#" onClick={() => handleDateClick('deletedaterange')}>Eliminar por rango de fechas</a></li>
  </ul>

  {isOpen && <Popup
	content={typeOfSetting}
	handleClose={togglePopup}
	/>}

	{/* {isOpenAddNew && <Popup
	content={typeOfSetting}
	handleClose={togglePopupAddNew}
	/>} */}

	</>
);
};

export default Settings;
