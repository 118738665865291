import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom'
import logo from './logo.svg';
import './App.css';
import {checktoken} from './admin/Api';
import Navbar from './components/Navbar';
import {BrowserRouter as Router, Route, Link, Routes} from 'react-router-dom';

import { useLocalStorage } from "./admin/LocalStorage";

import Home from './pages';
import Cuadrante from './pages/cuadrante';
import Settings from './pages/settings';
import AnnualReport from './pages/annual';
import Teams from './pages/team';
import Blogs from './pages/blogs';
import SignUp from './pages/signup';

import {InitialTask} from './admin/InitialTask';
import {getServiciosByUserId, saveInitialConfig} from './admin/Api';

function App() {
  const [idUsuario, setIdUsuario] = useLocalStorage("idusuario", "");
	
	useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search);
		const term = queryParams.get("userid");
		const token = queryParams.get('token');

		setIdUsuario(term);


		// if(token){
		// 	let result = token.replace(" ", "+");
		// 	checktoken(result)
		// 	.then( (e) => {
		// 		if( e === 1)
		// 		{
		// 			if(term !== null){
		// 				setIdUsuario(term);
		// 				getServiciosByUserId(term)
		// 				.then( (e) => {
		// 					// setServicios(e);
		// 						if(e.length === 0){
		// 							saveInitialConfig(InitialTask, idUsuario)
		// 							.then( (e) => {
		// 								console.log('salvados los servicios iniciales')
		// 							});
		// 						}
		// 				})
		// 			}
		// 		}else{
		// 			window.location.href = "https://jucil.es/";
		// 		}
		// 	});
		// }else{
		// 	window.location.href = "https://jucil.es/";
		// }
		
	  return () => {
	  }
	}, [])


  const [screenSize, getDimension] = useState({
		dynamicWidth: window.innerWidth,
		dynamicHeight: window.innerHeight
	  });
	  const setDimension = () => {
		getDimension({
		  dynamicWidth: window.innerWidth,
		  dynamicHeight: window.innerHeight
		})
	  }
	  
	  useEffect(() => {
		window.addEventListener('resize', setDimension);
		
		return(() => {
			window.removeEventListener('resize', setDimension);
		})
	  }, [screenSize])


  return (
    <div className="App" style={{ 
		width: screenSize.dynamicWidth, 
		height: screenSize.dynamicHeight
		}}>
      <Router>
      {/* <Navbar /> */}
      <div>
        <Routes>
          <Route path='/cuadrante' exact element={<Cuadrante/>} />
		      <Route path='/settings' element={<Settings/>} />
        </Routes>
        <Navbar />
      </div>
    </Router>
    </div>
  );
}


export default App;
