import React from 'react';

const Goto = () => {
return (
	<div
	style={{
		display: 'flex',
		justifyContent: 'Right',
		alignItems: 'Right',
		height: '100vh'
	}}
	>
	<h1>Goto</h1>
	</div>
);
};

export default Goto;
