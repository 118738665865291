
// const API = 'http://192.168.1.22:3009';
// const API = 'http://localhost:4434';
// const API = 'https://5.175.45.86:4434';

const API = 'https://app.jucil.es:4434';

export const saveOptions = async (options, id) => {
  const res = await fetch(
    `${API}/userconfig/${id}`, 
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        "Content-Type": 'application/json'
      },
      body: JSON.stringify(options)
    });
    return await res.json();
}

export const getUserConfig = async (id) => {
  const res = await fetch(
  `${API}/userconfig/${id}`,
  {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      "Content-Type": 'application/json'
    }
  });
  return await res.json();
}

export const getHolidays = async (idusuario) => {
  const res = await fetch(
    `${API}/getHolidays/${idusuario}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        "Content-Type": 'application/json'
      }
    }
  );
  return await res.json();
}

export const getPrivateAffairs = async (idusuario) => {
  console.log('PPPPPP', `${API}/getPrivateAffairs/${idusuario}`)
  const res = await fetch(
    `${API}/getPrivateAffairs/${idusuario}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        "Content-Type": 'application/json'
      }
    }
  );
  return await res.json();
}



export const saveInitialConfig = async (elemnt, id) => {
  const res = await fetch(
    `${API}/userinitialconfig/${id}`, 
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        "Content-Type": 'application/json'
      },
      body: JSON.stringify(elemnt)
    });
    return await res.json();
}

export const saveServicio = async (data, id) => {
  const res = await fetch(
    `${API}/saveservicio/${id}`, 
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        "Content-Type": 'application/json'
      },
      body: JSON.stringify(data)
    });
    return await res.json();
}

export const actualizarServicio = async (data) => {

  const res = await fetch(
    `${API}/updateservicio`, 
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        "Content-Type": 'application/json'
      },
      body: JSON.stringify(data)
    });
    return await res.json();
}

export const updateCalendarTask = async (data) => {
  const res = await fetch(
    `${API}/updateCalendarTask`, 
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        "Content-Type": 'application/json'
      },
      body: JSON.stringify(data)
    });
    return await res.json();
}

export const getServiciosByUserId = async (id) => {
  const res = await fetch(
  `${API}/getServiciosByUserId/${id}`,
  {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      "Content-Type": 'application/json'
    }
  });
  return await res.json();
}


export const deleteSevices = async (idusuario, idservicio) => {

  let data = {  
    idservicio: idservicio,
  }
  const res = await fetch(
    `${API}/task/${idusuario}`, 
    {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        "Content-Type": 'application/json'
      },
      body: JSON.stringify(data)
    });
    return await res.json();
}

export const deleteCalendarTask = async (idevent, idservicio, idusuario) => {

  let data = {  
    idevent: idevent,
    idservicio: idservicio,
    idusuario: idusuario
  }
  console.log('Data delete task calendar', data)
  const res = await fetch(
    `${API}/deletecalendartask/${idusuario}`, 
    {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        "Content-Type": 'application/json'
      },
      body: JSON.stringify(data)
    });
    return await res.json();
}



export const createTask = async (task) => {
  const res = await fetch(
    `${API}/task`, 
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        "Content-Type": 'application/json'
      },
      body: JSON.stringify(task)
    });
    return await res.json();
}

export const getTasks = async (data) => {
  const res = await fetch(
    `${API}/tasksweb`, 
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        "Content-Type": 'application/json'
      },
      body: JSON.stringify(data)
    });
    return await res.json();
}

export const getTask = async (idUser, day) => {
  const res = await fetch(
    `${API}/task/${idUser}/${day}`,
    {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      "Content-Type": 'application/json'
    }
  })
  return await res.json();
}

export const getCalendarTask = async (idUser, idTask) => {
  const res = await fetch(
    `${API}/calendartask/${idUser}/${idTask}`,
    {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      "Content-Type": 'application/json'
    }
  })
  return await res.json();
}


export const getHourForMonth = async (id, data) => {
  const res = await fetch(
    `${API}/getHourForMonth/${id}`, 
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        "Content-Type": 'application/json'
      },
      body: JSON.stringify(data)
    });
    return await res.json();
}

export const getTurns = async (id) => {
  const res = await fetch(
    `${API}/getTurns/${id}`, 
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        "Content-Type": 'application/json'
      },
    });
    return await res.json();
}

export const savedTurns = async (data) => {
  const res = await fetch(
    `${API}/turnos`, 
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        "Content-Type": 'application/json'
      },
      body: JSON.stringify(data)
    });
    return await res.json();
}

export const deleteTurn = async (id, idusuario) => {

  let data = {  
    id: id,
    idusuario: idusuario
  }
  const res = await fetch(
    `${API}/deleteTurn`, 
    {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        "Content-Type": 'application/json'
      },
      body: JSON.stringify(data)
    });
    return await res.json();
}

export const deleteTurnsByDateRange = async (data) => {

  const res = await fetch(
    `${API}/deleteTurnsByDateRange`, 
    {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        "Content-Type": 'application/json'
      },
      body: JSON.stringify(data)
    });
    return await res.json();
}

export const checktoken = async (data) => {
  try {
    const response = await fetch(`https://jucil.es/wp-json/convulsio/v2/convulsio-check-token/${data}` );
     const json = await response.json();
     return json;
  } catch (error) {
     console.error(error);
     return null;
  }
}