import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from "@mui/material/Button";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from '@mui/x-date-pickers';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {getServiciosByUserId,createTask,savedTurns} from '../../admin/Api';

import { useLocalStorage } from "../../admin/LocalStorage";
import Popup from '../../components/Popup';
import '../../Form.css';


const AddTurns = (props) => {
	
	const [currencyLunes, setCurrencyLunes] = React.useState('');
	const [currencyMartes, setCurrencyMartes] = React.useState('');
	const [currencyMiercoles, setCurrencyMiercoles] = React.useState('');
	const [currencyJueves, setCurrencyJueves] = React.useState('');
	const [currencyViernes, setCurrencyViernes] = React.useState('');
	const [currencySabado, setCurrencySabado] = React.useState('');
	const [currencyDomingo, setCurrencyDomingo] = React.useState('');

	// const [createTurn, setCreateTurn] = (defaultTurn());
	// const [turn, setTurn] = useState(defaultTurn());
	const [turn, setTurn] = useState([]);

	const handleChange = (event, diasemana) => {
		var obj = servicios.find( (e) => {
			if(e.ID === event.target.value){
				return e;
			}
		});
		setCurrencyLunes('');
		setTurn( [...turn, obj]);
	};


	const [idUsuario, setIdUsuario] = useLocalStorage("idusuario", "");
	const [servicios, setServicios] = useState([]);
	const [eventOpen, setEventOpen] = useState({});
	const [isOpenEvent, setIsOpenEvent] = useState(false);
	const [horadeinicio, setHoradeinicio] = useState();
  	const [horadefin, setHoradefin] = useState();

	useEffect(() => {
		getServiciosByUserId(idUsuario)
		.then( (e) => {
			setServicios(e);
			setEventOpen('serviceaddcuadrante');
		})
	}, []);

	const [valueStart, setValueStart] = React.useState(new Date());
	const handleChangeStart = (newValue) => {
		// let fecha = newValue.toISOString();
		// let fechaInicio = fecha.replace(/T/, ' ').replace(/\..+/, '');
		// setTurn( {...turn, FechaInicio: newValue.target.value});
		setValueStart(newValue.target.value);
		setHoradeinicio(newValue.target.value);
	};

	const [valueEnd, setValueEnd] = React.useState(new Date());
	const handleChangeEnd = (newValue) => {
		// let fecha = newValue.toISOString();
		// let fechaFin = fecha.replace(/T/, ' ').replace(/\..+/, '');
		// setTurn( {...turn, FechaFin: newValue.target.value});
		setValueEnd(newValue.target.value);
		setHoradefin(newValue.target.value);
	};

  const togglePopup = () => {
	setIsOpenEvent(!isOpenEvent);
  }

  const handleAddTurn = () => {
	// console.log('FFFFFF', turn)
	const d1 = new Date(valueStart);
	const d2 = new Date(valueEnd);
	let result = getDatesInRange(d1, d2, turn);
	let turno = {
		idusuario: idUsuario,
		fechainicio: valueStart,
		fechafin: valueEnd
	}

	savedTurns(turno)
	.then( (e) => {
		console.log('guardar turno', turno);
		props.cerrar();
	});


  }

  return (
	  <>
    	<Box sx={{ flexGrow: 1 }}>
			<TextField
			id="servicio"
			select
			label="Servicio"
			value={currencyLunes}
			onChange={(e) => handleChange(e,'servicio')}
			helperText="Selecciona el servicio"
			style={{ marginRight: 7}}
			>
			{servicios.map((option) => (
				<MenuItem key={option.ID} value={option.ID}>
				{option.titulo}
				</MenuItem>
			))}
			</TextField>
		</Box>
		
		<Box sx={{ flexGrow: 1 }}>
			<List>
				{turn.map( (opcion) => (
					<ListItem disablePadding>
						<ListItemButton>
						<ListItemText primary={opcion.titulo} />
						</ListItemButton>
					</ListItem>
				))}
			</List>
		</Box>

		<Box sx={{ flexGrow: 1 }}>
			<TextField
				id="date"
				label="Desde:"
				type="date"
				// defaultValue="2017-05-24"
				sx={{ width: 220 }}
				InputLabelProps={{
				shrink: true,
				}}
				onChange={handleChangeStart}
				style={{ marginBottom: 20}}
			/>

			<TextField
				id="date"
				label="Hasta:"
				type="date"
				// defaultValue="2017-05-24"
				sx={{ width: 220 }}
				InputLabelProps={{
				shrink: true,
				}}
				onChange={handleChangeEnd}
				style={{ marginBottom: 20}}
			/>
		</Box>

		<Box sx={{ flexGrow: 1 }}>
			<Button variant="outlined" color="success" style={{ marginRight: 7}}
				onClick={() => {
					// console.log('Manejador añadir turno')
				handleAddTurn()
				}}
				>
				Añadir turno
			</Button>
			<Button variant="outlined" color="error"
				onClick={() => {
					props.cerrar();
				}}
				>
				Cerrar
			</Button>
    </Box>
		{isOpenEvent && <Popup
			content={eventOpen}
			handleClose={togglePopup}
			listservices={servicios}
			diaclicado={null}
			dateClicked={null}
		  />}
	</>
  );
};

export default AddTurns;


function defaultTurn(){
    return {
        Lunes: false,
        Martes: false,
        Miercoles: false,
        Jueves: false,
        Viernes: false,
        Sabado: false,
        Domingo: false,
		FechaInicio: false,
		FechaFin: false
    }
}

function padTo2Digits(num) {
	return num.toString().padStart(2, '0');
  }
  
  function formatDate(date) {
	return [
		date.getFullYear(),
		padTo2Digits(date.getMonth() + 1),
	  	padTo2Digits(date.getDate()),
	].join('-');
  }

function getDatesInRange(startDate, endDate, turn) {
	const date = new Date(startDate.getTime());
	const dates = [];

	let contador = 0;
	let maxCount = turn.length -1;
	while (date <= endDate) {
		if( contador <= maxCount){
			var d = new Date(formatDate(date));
			d.setDate(d.getDate());
			var fecha = d.toISOString();
			var fechacalendario = fecha.replace(/T/, ' ').replace(/\..+/, '').replace(' 00:00:00','');
			turn[contador].fecha = d;
			turn[contador].fechacalendario = fechacalendario;
			
			createTask(turn[contador]);

			// if(turn[contador].idservicio === 13 || turn[contador].idservicio === 22){
			// 	contador ++;
			// 	var d = new Date(date);
			// 	d.setDate(d.getDate() + 1);
			// 	console.log('00000BBBBBB', d)
			// 	date.setDate(date.getDate() + 1);
			// 	let fecha = d.toISOString();
			// 	let fechacalendario = fecha.replace(/T/, ' ').replace(/\..+/, '').replace(' 00:00:00','');
			// 	let dataObj = {
			// 		'abreviatura': "S",
			// 		'colordefondo': "#cfd926",
			// 		'colordetexto': "#FFD633",
			// 		'diadeducible': "0",
			// 		'fecha': d,
			// 		'fechacalendario': fechacalendario,
			// 		'fechafin': fechacalendario + ' 00:00:00',
			// 		'fechainicio': fechacalendario + ' 06:00:00',
			// 		'guardiacombinada': "0",
			// 		'icon': "format-list-bulleted-type",
			// 		'idservicio': 21,
			// 		'idusuario': "14303",
			// 		'rangodefechas': "0",
			// 		'titulo': "SALIENTE",
			// 	}
			// 	createTask(dataObj);
			// }

		}else{
			contador = 0;
			var d = new Date(formatDate(date));
			d.setDate(d.getDate());
			var fecha = d.toISOString();
			var fechacalendario = fecha.replace(/T/, ' ').replace(/\..+/, '').replace(' 00:00:00','');
			turn[0].fecha = d;
			turn[0].fechacalendario = fechacalendario;

			createTask(turn[0]);

			// if(turn[0].idservicio === 13 || turn[0].idservicio === 22){
			// 	contador ++;
			// 	var d = new Date(date);
			// 	d.setDate(d.getDate() + 1);
			// 	date.setDate(date.getDate() + 1);
			// 	console.log('111111BBBBBBB', d)
			// 	let fecha = d.toISOString();
			// 	let fechacalendario = fecha.replace(/T/, ' ').replace(/\..+/, '').replace(' 00:00:00','');
			// 	let dataObj = {
			// 		'abreviatura': "S",
			// 		'colordefondo': "#cfd926",
			// 		'colordetexto': "#FFD633",
			// 		'diadeducible': "0",
			// 		'fecha': d,
			// 		'fechacalendario': fechacalendario,
			// 		'fechafin': fechacalendario + ' 00:00:00',
			// 		'fechainicio': fechacalendario + ' 06:00:00',
			// 		'guardiacombinada': "0",
			// 		'icon': "format-list-bulleted-type",
			// 		'idservicio': 21,
			// 		'idusuario': "14303",
			// 		'rangodefechas': "0",
			// 		'titulo': "SALIENTE",
			// 	}
			// 	createTask(dataObj);
			// }
			// return 1;
		}

	  let newElement = {};
	  // dates.push(new Date(date));
	  date.setDate(date.getDate() + 1);
	  contador ++;
  
	}
  
	return dates;
  }
