import React, {useState} from "react";
import '../Form.css';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from "@mui/material/Button";
import Stack from '@mui/material/Stack';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import ColorPicker from '../admin/ColorPicker';

 
const PopupServiceType = props => {

  const [titulo, setTitulo] = useState(props.content.titulo);
  const [abreviatura, setAbreviatura] = useState(props.content.abreviatura);
  const [rangodefechas, setRangodefechas] = useState(props.content.rangodefechas);
  const [colordefondo, setColordefondo] = useState(props.content.colordefondo);
  const [diadeducible, setDiadeducible] = useState(props.content.diadeducible);
  const [guardiacombinada, setGuardiacombinada] = useState(props.content.guardiacombinada);
  // const [horadeinicio, setHoradeinicio] = useState(new Date(new Date(props.content.fechainicio).getTime() - new Date(props.content.fechainicio).getTimezoneOffset() * 60 * 1000));
  // const [horadefin, setHoradefin] = useState(new Date(new Date(props.content.fechafin).getTime() - new Date(props.content.fechafin).getTimezoneOffset() * 60 * 1000));
  const [horadeinicio, setHoradeinicio] = useState();
  const [horadefin, setHoradefin] = useState();

  const handleChangeTitulo = (event) => {
    setTitulo(event.target.value);
  }
  const handleChangeAbreviatura = (event) => {
    setAbreviatura(event.target.value);
  }
  const handleChangeColorDeFondo = (data) => {
    setColordefondo(data);
  }
  const handleChangeHoraDeInicio = (event) => {
    setHoradeinicio(event.target.value);
  }
  const handleChangeHoraDeFin = (event) => {
    setHoradefin(event.target.value);
  }

  const [state, setState] = React.useState({
    rangofechas: (rangodefechas === '1') ? true : false,
    diadeducible: (diadeducible === '1') ? true : false,
    guardiacombinada: (guardiacombinada === '1') ? true : false
  });

  const handleToggle = ({ target }) => {
    setState(s => ({ ...s, [target.name]: !s[target.name] }));
  }
  

  const [valueStart, setValueStart] = React.useState(new Date());
  const handleChangeStart = (newValue) => {
    setValueStart(newValue);
    setHoradeinicio(newValue);
  };

  const [valueEnd, setValueEnd] = React.useState(new Date());
  const handleChangeEnd = (newValue) => {
    console.log(new Date(newValue));
    setValueEnd(newValue);
    setHoradefin(newValue);
  };

  console.log('PROPS content', props.content)
  return (
    <div className="popup-box">
      <div className="box">
        <span className="close-icon" onClick={() => props.handleClose(0)}>x</span>
        

        
        <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <TextField
            id="titulo"
            name="titulo"
            label="Título"
            fullWidth
            autoComplete="given-name"
            variant="standard"
            color="success"
            defaultValue={ titulo }
            onChange={handleChangeTitulo}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            id="abreviatura"
            name="abreviatura"
            label="Abreviatura"
            fullWidth
            autoComplete="family-name"
            variant="standard"
            color="success"
            defaultValue={ abreviatura }
            onChange={handleChangeAbreviatura}
          />
        </Grid>
        {/* <Grid item xs={12} sm={12}>
          <FormControlLabel
            control={<Checkbox color="success" name="rangofechas" value="1" defaultChecked={state.rangofechas}/>}
            label="Rango de fechas"
            onChange={handleToggle}
          />
        </Grid> */}
        <Grid item xs={12} sm={12}>
          <ColorPicker colordefondo={colordefondo} handlecolordefondo={handleChangeColorDeFondo}/>
        </Grid>
        {/* <Grid item xs={12} sm={12}>
          <FormControlLabel
            control={<Checkbox color="success" name="diadeducible" value="1" defaultChecked={state.diadeducible}/>}
            label="Día deducible"
            onChange={handleToggle}
          />
        </Grid> */}
        {/* <Grid item xs={12} sm={12}>
          <FormControlLabel
            control={<Checkbox color="success" name="guardiacombinada" value="1" defaultChecked={state.guardiacombinada} />}
            label="Guardia combinada"
            onChange={handleToggle}
          />
        </Grid> */}
        {/* <Grid item xs={12} sm={12}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={3}>
              <TimePicker
                label="Hora de inicio"
                value={horadeinicio}
                onChange={(newValue) => {
                  handleChangeStart(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
              
              <TimePicker
                label="Hora de fin"
                value={horadefin}
                onChange={handleChangeEnd}
                renderInput={(params) => <TextField {...params} />}
              />
            </Stack>
          </LocalizationProvider>
        </Grid> */}
        
        <Grid item xs={12} sm={12} style={{ display: "flex", flexDirection: "row", justifyContent: 'space-around' }}>
          <Button variant="contained" color="success"
            onClick={() => {
              let data = {  
                idservicio: props.content.ID,
                titulo: titulo,
                abreviatura: abreviatura,
                rangodefechas: rangodefechas,
                colordefondo: colordefondo,
                colordetexto: '#FFD633',
                diadeducible: diadeducible,
                guardiacombinada: guardiacombinada,
                icon: 'format-list-bulleted-type',
                fechainicio: horadeinicio,
                fechafin: horadefin
                // fechainicio: new Date(horadeinicio.getTime() - horadeinicio.getTimezoneOffset() * 60 * 1000),
                // fechafin: new Date(horadefin.getTime() - horadefin.getTimezoneOffset() * 60 * 1000)
              }

              props.handlesavestask(data);
            }}
          >
            Guardar
          </Button>
          {
          (props.content.idservicio > 18) ?
          <Button variant="outlined" color="error"
          onClick={() => {
            props.handleBorrar(props.content.ID);
          }}
        >
           Borrar
          </Button>
        :
          <Button variant="outlined" color="error"
            onClick={() => {
              props.handleClose(props.content.ID);
            }}
          >
             Cerrar
          </Button>
        }
           
        </Grid>
      </Grid>
    </React.Fragment>



      </div>
    </div>
  );
};


 
export default PopupServiceType;