import React, {useState, useEffect} from 'react';

import Button from "@mui/material/Button";

import {InitialTask} from '../../admin/InitialTask';
import { useLocalStorage } from "../../admin/LocalStorage";
import {getServiciosByUserId, saveInitialConfig, actualizarServicio, deleteSevices,createTask} from '../../admin/Api';

import PopupServiceType from '../PopupServiceType';
import { id } from 'date-fns/locale';

const ServiceAddCuadrante = (props) => {
	const [idUsuario, setIdUsuario] = useLocalStorage("idusuario", "");
	const [servicios, setServicios] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const [typeOfSetting, setTypeOfSetting] = useState();

	useEffect(() => {
		getServiciosByUserId(idUsuario)
		.then( (e) => {
		  if(e.length === 0){
			saveInitialConfig(InitialTask, idUsuario);
			setServicios(InitialTask);
		  }else{
			setServicios(e);
		  }
		})

	  return () => {
	  }
	}, [])
	
	const handleServiceType = (data) => {
		data.fechacalendario = props.diaclicado;
		data.fecha = props.dateClicked;
		console.log('creamos task en base de datos',data);

		createTask(data)
		.then( (e) => {
			if(data.idservicio === 13 || data.idservicio === 22){
				var d = new Date(props.diaclicado);
				d.setDate(d.getDate() + 1);
				let fecha = d.toISOString();
				let fechacalendario = fecha.replace(/T/, ' ').replace(/\..+/, '').replace(' 00:00:00','');
				let dataObj = {
					'abreviatura': "S",
					'colordefondo': "#cfd926",
					'colordetexto': "#FFD633",
					'diadeducible': "0",
					'fecha': d,
					'fechacalendario': fechacalendario,
					'fechafin': fechacalendario + ' 00:00:00',
					'fechainicio': fechacalendario + ' 06:00:00',
					'guardiacombinada': "0",
					'icon': "format-list-bulleted-type",
					'idservicio': 21,
					'idusuario': "14303",
					'rangodefechas': "0",
					'titulo': "SALIENTE",
				}
				createTask(dataObj)
				.then( (e) => {
					props.cerrar();
				});
			}
			props.cerrar();
		});
  }


	const togglePopup = (data) => {
		setIsOpen(!isOpen);
	}

	const handleSavesTask = (data) => {
		actualizarServicio(data)
		.then( (e) => {
			getServiciosByUserId(idUsuario)
			.then( (e) => {
				setServicios(e);
			})
			setIsOpen(!isOpen);
		})
	}

	const handleBorrar = (data) => {
		console.log(data);
		deleteSevices(idUsuario, data)
		.then( (e) => {
			console.log(e);
			getServiciosByUserId(idUsuario)
			.then( (e) => {
			setServicios(e);
			})
			setIsOpen(!isOpen);
		})
	}

return (
  <>
	<div
	style={{
		display: 'flex',
		justifyContent: 'flex-start',
		flexDirection: 'column',
		alignItems: 'normal',
		height: '100vh',
	}}
	>
	<div>
		<ul>
			{servicios.map((prop, key) => {
			return (
				// <Button style={{borderColor: prop[0]}}  key={key}>{prop[1]}</Button>
				<li key={prop.ID}>
				<img src="settingslist.svg" alt="settings" className="li__logo" />
				<span onClick={() => handleServiceType(prop)} style={{fontSize: 12}}>{prop.titulo}</span>
				</li>
			);
			})}
		</ul>
	</div>
	</div>
  {/* {isOpen && <PopupServiceType
	content={typeOfSetting}
	handleClose={togglePopup}
	handlesavestask={handleSavesTask}
	handleBorrar={handleBorrar}
	/>} */}
  </>
);
};

export default ServiceAddCuadrante;
