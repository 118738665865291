import React, {useState} from "react";
import '../Form.css';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from "@mui/material/Button";
import Stack from '@mui/material/Stack';

 
const PopupSettings = props => {
  const [horasSemanales, setHorasSemanales] = useState(props.formData.horassemanales);
  const [computoHoras, setComputoHoras] = useState(props.formData.computohoras);
  const [reduccionjornada, setReduccionjornada] = useState(props.formData.reduccionjornada);
  const [diasdevacaciones, setDiasdevacaciones] = useState(props.formData.diasdevacaciones);
  const [tiposerviciovacaciones, setTiposerviciovacaciones] = useState(props.formData.tiposerviciovacaciones);
  const [mostrarManutencionDeUnDia, setMostrarManutencionDeUnDia] = useState(props.formData.mostrarmanutenciondeundia);
  const [longituddias, setLongituddias] = useState(props.formData.longituddias);
  const [visualizardietatotal, setVisualizardietatotal] = useState(props.formData.visualizardietatotal);
  const [manutenciondiaria, setManutenciondiaria] = useState(props.formData.manutenciondiaria);
  const [dietadiaria, setDietadiaria] = useState(props.formData.dietadiaria);
  const [tiposervicioasuntosaparticulares, setTiposervicioasuntosaparticulares] = useState(props.formData.tiposervicioasuntosparticualres);
  const [asuntosparticulares, setAsuntosparticulares] = useState(props.formData.asuntosparticulares);




  const handleChangeHorasSemanales = (event) => {
    setHorasSemanales(event);
  };

  const handleChangeComputoHoras = (event) => {
    setComputoHoras(event);
  }

  const handleTipoServicioVacaciones = (event) => {
    setTiposerviciovacaciones(event);
  }

  const handleTipoServicioAsuntosParticualres = (event) => {
    setTiposervicioasuntosaparticulares(event);
  }

  const handleVisualizarDieTatotal = (event) => {
    setVisualizardietatotal(event);
  }

  const handleMostrarManutencionDeUnDia = (event) => {
    setMostrarManutencionDeUnDia(event);
  }

  return (
    <div className="popup-box">
      <div className="box">
        <span className="close-icon" onClick={props.handleClose}>x</span>
        {( () => {
          switch(props.content){
            case 'horassemanales':
              return(
              <HorasSemanales 
              handlechangehorassemanales={handleChangeHorasSemanales}
              horasSemanales={horasSemanales}
              handleclose={props.handleClose}
              handlesavesetting={props.handlesavesetting}
              />
              )
              break;
            case 'computohoras':
              return (
                <ComputoHoras 
                handleChangeComputoHoras={handleChangeComputoHoras}
                computoHoras={computoHoras}
                handleclose={props.handleClose}
                handlesavesetting={props.handlesavesetting}
                />
              )
              break;
            case 'reduccionjornada':
              return(
                <ReduccionDeJornada
                reduccionjornada={reduccionjornada}
                handleclose={props.handleClose}
                handlesavesetting={props.handlesavesetting}
                />
              );
              break;
            case 'diadevacaciones':
              return (
                <Vacaciones 
                diasdevacaciones={diasdevacaciones}
                handleclose={props.handleClose}
                handlesavesetting={props.handlesavesetting}
                />
              )
              break;
            case 'tipodeservicio':
              return (
                <TipoServicioVacaciones
                handleTipoServicioVacaciones={handleTipoServicioVacaciones}
                tiposerviciovacaciones={tiposerviciovacaciones}
                handleclose={props.handleClose}
                handlesavesetting={props.handlesavesetting}
                />
              )
              break;
            case 'asuntosparticulares':
              return (
                <AsuntosParticulares
                asuntosparticulares={asuntosparticulares} 
                handleclose={props.handleClose}
                handlesavesetting={props.handlesavesetting}
                />
              )
              break;
            case 'tiposervicioasuntosparticulares': 
              return (
                <TipoServicioAsuntosParticualres 
                handleTipoServicioAsuntosParticualres={handleTipoServicioAsuntosParticualres}
                tiposervicioasuntosaparticulares={tiposervicioasuntosaparticulares}
                handleclose={props.handleClose}
                handlesavesetting={props.handlesavesetting}
                />
              )
              break;
            case 'dietadiarioa':  
              return (
                <DietaDiaria
                dietadiaria={dietadiaria} 
                handleclose={props.handleClose}
                handlesavesetting={props.handlesavesetting}
                />
              )
              break;
            case 'manutenciondiaria': 
              return (
                <ManutencionDiaria
                manutenciondiaria={manutenciondiaria}
                handleclose={props.handleClose}
                handlesavesetting={props.handlesavesetting}
                />
              )
              break;
            case 'visualizardietatotal': 
              return (
                <VisualizarDieTatotal
                handleVisualizarDieTatotal={handleVisualizarDieTatotal} 
                visualizardietatotal={visualizardietatotal}
                handleclose={props.handleClose}
                handlesavesetting={props.handlesavesetting}
                />
              )
              break;
            case 'longitudendias':
              return (
                <LongitudDias
                longituddias={longituddias}
                handleclose={props.handleClose}
                handlesavesetting={props.handlesavesetting}
                />
              )
              break;
            case 'mostrarmanutenciondeundia':
              return (
                <MostrarManutencionDeUnDia
                handleMostrarManutencionDeUnDia={handleMostrarManutencionDeUnDia}
                mostrarManutencionDeUnDia={mostrarManutencionDeUnDia}
                handleclose={props.handleClose}
                handlesavesetting={props.handlesavesetting}
                />
              )
              break;
          }
        })()}
      </div>
    </div>
  );
};


const HorasSemanales = (props) => {
  return (
  <Grid container spacing={3}>
    <Grid item xs={12} sm={12}>
      <FormControl>
      <FormLabel id="demo-radio-buttons-group-label">
      <h2>Horas semanales</h2>
      <p>Elija la jornada de cómputo adecuada a su especialidad.</p>
      </FormLabel>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        value={props.horasSemanales}
        onChange={ (event) => {
          props.handlechangehorassemanales(event.target.value);
          props.handlesavesetting(event.target.value,'horassemanales');
        }}
      >
        <FormControlLabel value="37.5" control={<Radio />} label="37.5 Horas/Semana" />
        <FormControlLabel value="40" control={<Radio />} label="40 Horas/Semana" />
      </RadioGroup>
      </FormControl>

      <div style={{ textAlign: 'center', marginTop: 20 }}>
      <Button 
        variant="contained" 
        color="success" 
        onClick={() => {
          props.handleclose();
        }}>
        Cerrar
      </Button>
    </div>
    </Grid>
  </Grid> 
  );
}


const ComputoHoras = (props) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">
          <h2>Cómputo de horas</h2>
          <p>Elija el tipo de cómputo de horas.</p>
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            value={props.computoHoras}
            onChange={ (event) => {
              props.handleChangeComputoHoras(event.target.value);
              props.handlesavesetting(event.target.value,'computohoras');
            }}
          >
            <FormControlLabel value="1" control={<Radio />} label="Mensual" />
            <FormControlLabel value="3" control={<Radio />} label="Trimestral" />
            <FormControlLabel value="4" control={<Radio />} label="Cuatrimestral" />
          </RadioGroup>
        </FormControl>
        <div style={{ textAlign: 'center', marginTop: 20 }}>
          <Button 
            variant="contained" 
            color="success" 
            onClick={() => {
              props.handleclose();
            }}>
            Cerrar
          </Button>
        </div>
      </Grid>
    </Grid>
  )
}

const ReduccionDeJornada = (props) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <div style={{ textAlign: 'center' }}>
          <TextField
          id="outlined-number"
          label="Reducción de jornada %"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          defaultValue={ props.reduccionjornada }
            onChange={ (event) => props.handlesavesetting(event.target.value,'reduccionjornada')}
        />
        </div>
      </Grid>
      <Grid item xs={12} sm={12}>
        <div style={{ textAlign: 'center' }}>
          <Button 
          style={{ marginTop: 20}}
            variant="contained" 
            color="success" 
            onClick={() => {
              props.handleclose();
            }}>
            Cerrar
          </Button>
        </div>
      </Grid>
    </Grid>
  )
}

const Vacaciones = (props) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <div style={{ textAlign: 'center' }}>
          <TextField
          id="outlined-number"
          label="Días de Vacaciones"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          defaultValue={ props.diasdevacaciones }
            onChange={ (event) => props.handlesavesetting(event.target.value,'diasdevacaciones')}
        />
        </div>
      </Grid>
      <Grid item xs={12} sm={12}>
        <div style={{ textAlign: 'center' }}>
          <Button 
          style={{ marginTop: 20}}
            variant="contained" 
            color="success" 
            onClick={() => {
              props.handleclose();
            }}>
            Cerrar
          </Button>
        </div>
      </Grid>
    </Grid>
  )
}

const TipoServicioVacaciones = (props) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">
          <h2>Tipo de servicio</h2>
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            value={props.tiposerviciovacaciones}
            onChange={ (event) => {
              props.handleTipoServicioVacaciones(event.target.value);
              props.handlesavesetting(event.target.value,'tiposerviciovacaciones');
            }}
          >
            <FormControlLabel value="1" control={<Radio />} label="Alerta" />
            <FormControlLabel value="2" control={<Radio />} label="Asuntos particulares" />
            <FormControlLabel value="3" control={<Radio />} label="Baja" />
            <FormControlLabel value="4" control={<Radio />} label="Descanso compensatorio" />
            <FormControlLabel value="5" control={<Radio />} label="Descanso semanal" />
            <FormControlLabel value="6" control={<Radio />} label="Descnaso singularizado" />
            <FormControlLabel value="7" control={<Radio />} label="Guardia combinada" />
          </RadioGroup>
        </FormControl>
        <div style={{ textAlign: 'center', marginTop: 20 }}>
          <Button 
            variant="contained" 
            color="success" 
            onClick={() => {
              props.handleclose();
            }}>
            Cerrar
          </Button>
        </div>
      </Grid>
    </Grid>
  )
}

const AsuntosParticulares = (props) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <div style={{ textAlign: 'center' }}>
          <TextField
          id="outlined-number"
          label="Asuntos particulares"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          defaultValue={ props.asuntosparticulares }
            onChange={ (event) => props.handlesavesetting(event.target.value,'asuntosparticulares')}
        />
         
        </div>
      </Grid>
      <Grid item xs={12} sm={12}>
      <div style={{ textAlign: 'center' }}>
      <Button 
          style={{ marginTop: 20}}
            variant="contained" 
            color="success" 
            onClick={() => {
              props.handleclose();
            }}>
            Cerrar
          </Button>
      </div>
      </Grid>
    </Grid>
  )
}

const TipoServicioAsuntosParticualres = (props) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">
          <h2>Tipo de servicio</h2>
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            value={props.tiposervicioasuntosaparticulares}
            onChange={ (event) => {
              props.handleTipoServicioAsuntosParticualres(event.target.value);
              props.handlesavesetting(event.target.value,'tiposervicioasuntosparticualres');
            }}
          >
            <FormControlLabel value="1" control={<Radio />} label="Alerta" />
            <FormControlLabel value="2" control={<Radio />} label="Asuntos particulares" />
            <FormControlLabel value="3" control={<Radio />} label="Baja" />
            <FormControlLabel value="4" control={<Radio />} label="Descanso compensatorio" />
            <FormControlLabel value="5" control={<Radio />} label="Descanso semanal" />
            <FormControlLabel value="6" control={<Radio />} label="Descnaso singularizado" />
            <FormControlLabel value="7" control={<Radio />} label="Guardia combinada" />
          </RadioGroup>
        </FormControl>
        <div style={{ textAlign: 'center', marginTop: 20 }}>
          <Button 
            variant="contained" 
            color="success" 
            onClick={() => {
              props.handleclose();
            }}>
            Cerrar
          </Button>
        </div>
      </Grid>
    </Grid>
  )
}

const DietaDiaria = (props) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <div style={{ textAlign: 'center' }}>
          <TextField
          id="outlined-number"
          label="Dieta diaria"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          defaultValue={ props.dietadiaria }
            onChange={ (event) => props.handlesavesetting(event.target.value,'dietadiaria')}
        />
        </div>
      </Grid>
      <Grid item xs={12} sm={12}>
        <div style={{ textAlign: 'center' }}>
          <Button 
          style={{ marginTop: 20}}
            variant="contained" 
            color="success" 
            onClick={() => {
              props.handleclose();
            }}>
            Cerrar
          </Button>
        </div>
      </Grid>
    </Grid>
  )
}

const ManutencionDiaria = (props) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <div style={{ textAlign: 'center' }}>
          <TextField
          id="outlined-number"
          label="Manutención diaria"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          defaultValue={ props.manutenciondiaria }
            onChange={ (event) => props.handlesavesetting(event.target.value,'manutenciondiaria')}
        />
        </div>
      </Grid>
      <Grid item xs={12} sm={12}>
        <div style={{ textAlign: 'center' }}>
          <Button 
          style={{ marginTop: 20}}
            variant="contained" 
            color="success" 
            onClick={() => {
              props.handleclose();
            }}>
            Cerrar
          </Button>
        </div>
      </Grid>
    </Grid>
  )
}

const VisualizarDieTatotal = (props) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">
          <h2>Visualizar dieta total</h2>
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            value={props.visualizardietatotal}
            onChange={ (event) => {
              props.handleVisualizarDieTatotal(event.target.value);
              props.handlesavesetting(event.target.value,'visualizardietatotal');
            }}
          >
            <FormControlLabel value="true" control={<Radio />} label="Total" />
            <FormControlLabel value="false" control={<Radio />} label="80%, 20% + lavandaría" />
          </RadioGroup>
        </FormControl>
        <div style={{ textAlign: 'center', marginTop: 20 }}>
          <Button 
            variant="contained" 
            color="success" 
            onClick={() => {
              props.handleclose();
            }}>
            Cerrar
          </Button>
        </div>
      </Grid>
    </Grid>
  )
}

const LongitudDias = (props) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <div style={{ textAlign: 'center' }}>
          <TextField
          id="outlined-number"
          label="Longitud en días"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          defaultValue={ props.longituddias }
            onChange={ (event) => props.handlesavesetting(event.target.value,'longituddias')}
        />
        </div>
      </Grid>
      <Grid item xs={12} sm={12}>
        <div style={{ textAlign: 'center' }}>
          <Button 
          style={{ marginTop: 20}}
            variant="contained" 
            color="success" 
            onClick={() => {
              props.handleclose();
            }}>
            Cerrar
          </Button>
        </div>
      </Grid>
    </Grid>
  )
}

const MostrarManutencionDeUnDia = (props) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">
          <h2>Mostrar manutención de un día</h2>
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            value={props.mostrarManutencionDeUnDia}
            onChange={ (event) => {
              props.handleMostrarManutencionDeUnDia(event.target.value);
              props.handlesavesetting(event.target.value,'mostrarmanutenciondeundia');
            }}
          >
            <FormControlLabel value="true" control={<Radio />} label="Si" />
            <FormControlLabel value="false" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
        <div style={{ textAlign: 'center', marginTop: 20 }}>
          <Button 
            variant="contained" 
            color="success" 
            onClick={() => {
              props.handleclose();
            }}>
            Cerrar
          </Button>
        </div>
      </Grid>
    </Grid>
  )
}
 
export default PopupSettings;